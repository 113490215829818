var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"managingPeople hp100 bbox"},[_c('el-card',[_c('el-row',{attrs:{"gutter":20,"type":"flex","justify":"space-between"}},[(_vm.roleName == '小区管理员')?_c('el-col',[_c('el-button',{staticClass:"add-column-btn bbox",attrs:{"plain":"","type":"primary"},on:{"click":function($event){return _vm.gotoDetail('')}}},[_c('i',{staticClass:"el-icon-plus fw900 f16"}),_vm._v(" 新增 ")])],1):_vm._e(),_c('el-col',[_c('div',{staticClass:"flex align-center justify-end header-search-box"},[_c('el-input',{staticClass:"header-search-input ml10",attrs:{"placeholder":"请输入楼栋名","clearable":""},model:{value:(_vm.queryInfo.condition.buildName),callback:function ($$v) {_vm.$set(_vm.queryInfo.condition, "buildName", $$v)},expression:"queryInfo.condition.buildName"}},[_c('el-button',{staticClass:"textblue searchBtn",attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":function($event){return _vm.search()}},slot:"append"})],1)],1)])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","fit":"","height":_vm.height,"highlight-current-row":""},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"小区名称","align":"center","prop":"小区名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.villageName))])]}}])}),_c('el-table-column',{attrs:{"label":"楼栋名称","align":"center","prop":"楼栋名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.buildName))])]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","align":"center","prop":"创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("dataFormat")(scope.row.createDt)))])]}}])}),(
          _vm.roleName == '小区管理员' ||
          _vm.roleName == '超级系统管理员' ||
          _vm.roleName === '平台管理员' ||
          _vm.roleName == '街道管理员' ||
          _vm.roleName == '社区管理员'
        )?_c('el-table-column',{attrs:{"label":"操作","align":"center","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"编辑","placement":"bottom"}},[_c('i',{staticClass:"el-icon-edit textblue f16 ml20",on:{"click":function($event){return _vm.gotoDetail(scope.row.id, scope.row.userCode)}}})]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"删除","placement":"bottom"}},[_c('i',{staticClass:"el-icon-delete textblue f16 ml20",on:{"click":function($event){return _vm.del(scope.row.buildCode, scope.row.id)}}})])]}}],null,false,104203226)}):_vm._e()],1),_c('div',{staticClass:"footerBox"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.currPage,"page-sizes":[10, 20, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.queryInfo, "currPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryInfo, "currPage", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }